import RestClient from './RestClient';
import API_ENDPOINTS from './apiConfig';
import Cookies from 'universal-cookie';

export default class AuthManager {
  /**
   * gets current logged in user from backend and stores in local storage
   * @returns
   */
  static async initialize() {
    if (this.isLoggedIn()) {
      try {
        const response = await RestClient.get(API_ENDPOINTS.AUTH.MERCHANTS);
        return response;
      } catch (e) {
      }
    }
  }

  static isLoggedIn() {
    return !!this.getFullToken();
  }

  static getUser() {
    return localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  }

  static async logout(redirectTo = '/app') {
    const tokenInfo = JSON.parse(localStorage.getItem('tokenData'));
    const uatToken = this.getUATAppToken();
    await RestClient.delete(
      API_ENDPOINTS.AUTH.LOGOUT,
      { ...(uatToken ? { uat_token: `Bearer ${uatToken}` } : {}) },
    );
    let cookies = new Cookies();
    const options = {
      domain: 'payu.in',
      expires: new Date(tokenInfo['tokenLifeTime']),
      path: '/',
      secure: true
    };
    cookies.remove('mid', options);
    cookies.remove('product', options);
    cookies.remove('merchantAccessToken', options);
    cookies.remove('dashboardPreference', options);
    cookies.remove('product_account_uuid', options);
    cookies.remove('product_account_type', options);
    cookies.remove('product_accounts', options);
    cookies.remove('acl_role_name', options);
    cookies.remove('user_uuid', options);
    cookies.remove('merchant_account_uuid', options);
    cookies.remove('testAccessToken', options);
    cookies.remove('test_product_account_type', options);
    cookies.remove('test_product_account_uuid', options);
    cookies.remove('test_merchant_account_uuid', options);
    cookies.remove('test_mid', options);
    cookies.remove('selected_outlet', options);
    cookies.remove('selected_outlet_id', options);
    this.removeLocalStorageData();
    this.removeQueryParams();
    if (redirectTo) {
      window.location.assign(redirectTo);
    }
  }

  static removeLocalStorageData() {
    this.removeEmailLinkSent();
    this.removeFullToken();
    this.removeUserUuid();
    this.removeUserFromLocalStorage();
    this.removeTempToken();
    this.removeConsolidatedProductAccountFromLocalStorage();
  }

  static persistUserToLocalStorage(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  static getResellerId() {
    try {
      return JSON.parse(window.localStorage.getItem('reseller_uuid'));
    } catch (e) {
      return null;
    }
  }

  static removeUserFromLocalStorage() {
    localStorage.removeItem('user');
  }
  static removeUserUuid() {
    localStorage.removeItem('user_uuid');
  }
  static removeTempToken() {
    localStorage.removeItem('tempToken');
  }

  static removeConsolidatedProductAccountFromLocalStorage() {
    localStorage.removeItem('consolidated_product_accounts');
  }

  static getHubToken(hubUser) {
    if (hubUser && hubUser.attributes) {
      const { 'limited-token': token } = hubUser.attributes;
      return token['access-token'];
    }
    return null;
  }

  static persistFullToken(tokenData) {
    let data;
    try {
      const tokenLifeTime = new Date();
      tokenLifeTime.setSeconds(tokenData.expires_in);
      tokenData.tokenLifeTime = tokenLifeTime;
      data = JSON.stringify(tokenData);
    } catch (e) {
      console.warn(e);
    }
    if (!this.getFullToken()) {
      window.localStorage.setItem('tokenData', data);
    }
  }

  static getFullToken() {
    try {
      return JSON.parse(window.localStorage.getItem('tokenData')).access_token;
    } catch (e) {
      return null;
    }
  }

  static getUATAppToken() {
    let cookies = new Cookies();
    try {
      return cookies.get('testAccessToken')
    } catch (e) {
      return null;
    }
  }

  static getUserId() {
    try {
      return JSON.parse(window.localStorage.getItem('tokenData')).user_uuid;
    } catch (e) {
      return null;
    }
  }

  static getMerchantName() {
    try {
      return JSON.parse(window.localStorage.getItem('user')).name;
    } catch (e) {
      return null;
    }
  }

  static removeFullToken() {
    return window.localStorage.removeItem('tokenData');
  }

  static isSessionActive() {
    try {
      const tokenData = JSON.parse(window.localStorage.getItem('tokenData'));
      return Date.parse(tokenData.tokenLifeTime) > Date.now();
    } catch (e) {
      return null;
    }
  }

  static removeQueryParams() {
    return window.sessionStorage.removeItem('queryString')
  }

  static removeEmailLinkSent() {
    const emailData = localStorage.getItem(`${this.getUserId()}_verification_link_sent`);
    if (emailData) {
      return localStorage.removeItem(`${this.getUserId()}_verification_link_sent`);
    }
    return null;
  }

  static getUUID() {
    const tokenData = JSON.parse(window.localStorage.getItem('tokenData'));
    if(tokenData){
      return tokenData.user_uuid;
    } else {
      return null;
    }
  }
}
